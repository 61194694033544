<script setup lang="ts">
import { computed, resolveComponent } from 'vue'

const props = withDefaults(defineProps<{
  preIcon?: string
  theme?: keyof typeof themes
  href?: string
  to?: string
  target?: '_blank' | '_self' | '_parent' | '_top'
  ringOffsetColor?: string
  type?: 'button' | 'submit' | 'reset'
}>(), {
  theme: 'primary',
})

const themes = {
  primary: {
    classes: 'text-slate-700 bg-gradient-to-b from-lemon to-sunset rounded-full px-4 py-2 font-bold hover:drop-shadow-md focus:ring-2 focus:ring-offset-2 focus:ring-sunset',
    ringOffsetColor: 'ring-offset-void',
  },
  void: {
    classes: 'text-white uppercase bg-void border border-white rounded-full px-15 py-3 focus:ring-2 focus:ring-offset-2 focus:ring-lavender',
    ringOffsetColor: 'ring-offset-white',
  },
  lightVoid: {
    classes: 'text-void uppercase bg-white border border-void rounded-full px-15 py-3 focus:ring-2 focus:ring-offset-2 focus:ring-void',
    ringOffsetColor: 'ring-offset-white',
  },
  lavender: {
    classes: 'text-white uppercase bg-lavender border border-lavender rounded-full px-4 py-2 focus:ring-2 focus:ring-offset-2 focus:ring-lavender',
    ringOffsetColor: 'ring-offset-white',
  },
}

const linkComponent = computed(() => {
  if (props.href)
    return 'a'
  if (props.to)
    return resolveComponent('nuxt-link')
  return 'button'
})

const attrs = computed(() => {
  if (props.href)
    return { href: props.href }
  if (props.to)
    return { to: props.to }
  return {}
})
</script>

<template>
  <component
    :is="linkComponent"
    :class="{ [themes[props.theme].classes]: true, [props.ringOffsetColor ?? themes[theme].ringOffsetColor]: true }"
    class="inline-flex items-center gap-3 text-sm transition-all hover:cursor-pointer"
    :target="target"
    v-bind="attrs"
    :type="props.type"
  >
    <div v-if="preIcon" :class="preIcon" />
    <slot />
  </component>
</template>
